@tailwind base;
@tailwind components;
@tailwind utilities;

textarea {
  resize: none;
}
* {
  cursor: default;
}
ul li a.active {
  color: #0086b4 !important;
  border-bottom: #0086b4 2px solid !important;
}

.direction {
  direction: ltr !important;
}

.loadingPro div {
  background-color: #dadada;
  border-radius: 5px;
  animation: load 1.1s infinite;
}

@keyframes load {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
